import styled from 'styled-components';
import { Box } from '../../common/Box';
import BackgroundImage from 'assets/backgrounds/about-us-fcic.jpg';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)`
  grid-template-columns: minmax(730px, 1fr) minmax(710px, 1fr);
  min-height: calc(100vh - 52px);
  border-top: 1px solid ${COLORS.stroke.primary};
`;

export const StyledRightContainer = styled(Box)`
  background-image: url(${BackgroundImage});
  background-size: cover;
  width: 100%;
  height: 100%;
`;
