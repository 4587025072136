import styled from 'styled-components';
import { Box } from '../Box';
import BackgroundImage from 'assets/backgrounds/recommendations.jpg';
import { Typography } from '../Typography';

export const StyledContainer = styled(Box)`
  background-image: url(${BackgroundImage});
  background-size: cover;

  height: fit-content;
`;

export const StyledDescription = styled(Typography)`
  line-height: 25px;
  width: 60%;
`;
