import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { HashLink as Link } from 'react-router-hash-link';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledTypography = styled(Typography)`
  font-size: 11px;
  line-height: 18px;

  width: 60%;
  text-align: center;
`;

export const StyledLink = styled(Link)`
  color: ${COLORS.typography.body};
  transition-duration: 0.2s;

  &:hover {
    color: ${COLORS.accent.primary};
    text-decoration: none;
  }
`;
