import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledName = styled(Typography)`
  font-size: 24px;
  line-height: 26px;
  color: ${COLORS.typography.primary};
  margin-bottom: 10px;
`;

export const StyledPosition = styled(Typography)`
  line-height: 20px;
`;

export const StyledLinkedinLink = styled.a`
  transition-duration: 0.2s;
  flex: 0 1 12%;

  &:hover {
    svg {
      fill: ${COLORS.info.selectedBorder};
    }
  }
`;

export const StyledPhoto = styled.img`
  width: 100%;
  brder-radius: 16px;
`;
