import { AppPaths } from 'urls/frontend';
import { navigationItems as methodologyNavItem } from 'components/Methodology/MainSection/config';
import { navigationItems as aboutUsNavItem } from 'views/AboutUs/config';

const [
  fundSelectionApproach,
  smartFundBenchmarking,
  esgCredibilityScore,
  privateMarkets,
] = methodologyNavItem;

const { services, team, fcic } = aboutUsNavItem;

export const navigationItems = {
  aboutUs: {
    categoryName: 'Über uns',
    items: [
      {
        label: 'Dienstleistungen',
        link: `${AppPaths.aboutUs}#${services.id}`,
      },
      {
        label: 'Team',
        link: `${AppPaths.aboutUs}#${team.id}`,
      },
      {
        label: 'FondsConsult Investment Committee (FCIC)',
        link: `${AppPaths.aboutUs}#${fcic.id}`,
      },
    ],
  },
  methodology: {
    categoryName: 'Methodik',
    items: [
      {
        label: 'FCR Fondsselektion',
        link: `${AppPaths.methodology}#${fundSelectionApproach.id}`,
      },
      {
        label: 'Smart Fund Benchmarking',
        link: `${AppPaths.methodology}#${smartFundBenchmarking.id}`,
      },
      {
        label: 'ESG Credibility Score',
        link: `${AppPaths.methodology}#${esgCredibilityScore.id}`,
      },
      {
        label: 'Private Markets',
        link: `${AppPaths.methodology}#${privateMarkets.id}`,
      },
    ],
  },
};
