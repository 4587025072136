import { FC } from 'react';
import { ToastProps } from './Toast.d';
import { Typography } from '../Typography';
import { Box } from '../Box';
import { COLORS } from 'theme/colors';

export const Toast: FC<ToastProps> = ({ title, content }) => {
  const innerContent = Array.isArray(content) ? content : [content];

  return (
    <Box display="flex" flexDirection="column" gap={0.4}>
      {title && <Typography variant="subtitle">{title}</Typography>}
      {innerContent.map((line, index) => (
        <Typography
          key={index}
          variant="caption"
          color={COLORS.accent.secondary}
        >
          {line}
        </Typography>
      ))}
    </Box>
  );
};
