import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.background.module};
`;

export const StyledHeader = styled(Typography)`
  font-size: 64px;
`;

export const StyledSubHeader = styled(Typography)`
  font-size: 18px;
  line-height: 26px;
  color: ${COLORS.typography.description};
  text-align: center;

  margin-top: 24px;
  width: 45%;
`;

export const StyledFormContainer = styled(Box)`
  width: 45%;
  margin: 0 auto;
`;
