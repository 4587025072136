import { HashLink as Link } from 'react-router-hash-link';
import styled, { css } from 'styled-components';
import { StyledButtonProps, StyledLinkProps } from './NavigationMenu.d';
import { COLORS } from '../../../theme/colors';

const typographyStyles = css`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
`;

export const StyledButton = styled.button<StyledButtonProps>`
  ${typographyStyles}
  border: none;
  background-color: transparent;
  color: ${({ variant }) =>
    variant === 'primary' ? COLORS.typography.main : COLORS.accent.white};

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  text-underline-offset: 3px;

  transition-duration: 0.2s;

  ${({ open }) => open && 'text-decoration: underline; '}

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    transition-duration: 0.2s;
  }
`;

export const StyledLink = styled(Link)<StyledLinkProps>`
  ${typographyStyles}
  text-decoration: none;
  color: ${({ variant }) =>
    variant === 'primary' ? COLORS.typography.main : COLORS.accent.white};
  text-underline-offset: 3px;

  &:hover {
    text-decoration: underline;
  }
`;
