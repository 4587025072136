import styled, { keyframes } from 'styled-components';
import { Box } from 'components/common/Box';
import { COLORS } from 'theme/colors';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-70px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-70px);
  }
`;

type StyledFixedHeaderContainerProps = {
  showFixedHeader: boolean;
};

export const StyledFixedHeaderContainer = styled(
  Box,
)<StyledFixedHeaderContainerProps>`
  position: fixed;
  top: 0;
  z-index: 1;

  background-color: ${COLORS.background.secondary};
  width: 100%;

  animation: ${({ showFixedHeader }) => (showFixedHeader ? fadeIn : fadeOut)}
    0.5s ease-in-out;
`;
