import { FC } from 'react';
import {
  StyledContainer,
  StyledLeftContainer,
  StyledRightContainer,
} from './Studies.styled';
import { Typography } from '../../common/Typography';
import { COLORS } from '../../../theme/colors';
import { Box } from '../../common/Box';
import { Button } from '../../common/Button';
import { ButtonVariant } from '../../common/Button/Button.enums';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  StudiesCategory,
  StudiesClickedAction,
} from 'utils/google-analytics/events/studies';

export const Studies: FC = () => {
  const onGoTiStuidesClick = () => {
    TrackGoogleAnalyticsEvent(
      StudiesCategory,
      StudiesClickedAction,
      window.location.pathname,
    );
  };

  return (
    <StyledContainer display="grid" gridGap={2.5} px={8} py={12.5}>
      <StyledLeftContainer p={5}>
        <Typography variant="h2" color={COLORS.background.secondary}>
          Studien - umfassend und aktuell
        </Typography>
        <Box display="flex" flexDirection="column" gap={5}>
          <Typography variant="subtitle" color={COLORS.background.secondary}>
            Wir erstellen exklusiv Studien über Fonds und Asset Management
            Themen in einer kompakten und transparenten Form. Sie eignen sich
            für professionelle Berater wie auch für interessierte Anleger und
            Leser und liefern eine belastbare Basis für Anlageentscheidungen.
            Eine Übersicht zu den aktuellen FondsConsult-Studien finden Sie hier
          </Typography>
          <Button
            variant={ButtonVariant.CONTAINED}
            onClick={onGoTiStuidesClick}
            rightIcon={
              <ArrowIcon
                stroke={COLORS.info.selectedBorder}
                style={{ transform: 'rotate(-90deg)' }}
              />
            }
          >
            Go to Studies
          </Button>
        </Box>
      </StyledLeftContainer>
      <StyledRightContainer p={5}>
        <Typography variant="h2">
          FondsConsult-Datenbank - innovativ und intuitiv
        </Typography>
        <Box display="flex" flexDirection="column" gap={5}>
          <Typography variant="subtitle">
            Unsere intuitive Datenbank bietet umfassende Informationen zu
            Investmentfonds, einschließlich Private Markets und ELTIFs. Mit
            unserem Smart Fund Benchmarking identifizieren Sie schnell die USPs
            jedes Fonds. Zusätzlich erhalten Sie direkten Zugang zu unseren
            Experten über den Analyst Desk – für fundierte Entscheidungen und
            prompte Unterstützung.
          </Typography>
          <Button
            variant={ButtonVariant.SECONDARY}
            rightIcon={
              <ArrowIcon
                stroke={COLORS.info.selectedBorder}
                style={{ transform: 'rotate(-90deg)' }}
              />
            }
          >
            Ask for more
          </Button>
        </Box>
      </StyledRightContainer>
    </StyledContainer>
  );
};
