import { forwardRef } from 'react';
import { ButtonProps } from './Button.d';
import { StyledButton } from './Button.styled';
import { Box } from 'components/common/Box';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, leftIcon, rightIcon, ...props }, ref) => (
    <StyledButton ref={ref} {...props}>
      <Box display="flex" alignItems="center" justifyContent="center">
        {leftIcon && (
          <Box display="flex" ml={1} alignItems="center">
            {leftIcon}
          </Box>
        )}
        {children}{' '}
        {rightIcon && (
          <Box display="flex" ml={1} alignItems="center">
            {rightIcon}
          </Box>
        )}
      </Box>
    </StyledButton>
  ),
);

Button.displayName = 'Button';
