import styled from 'styled-components';
import { Box } from 'components/common/Box';
import BackgroundImage from 'assets/backgrounds/main-section.png';
import { Typography } from '../../common/Typography';
import { COLORS } from '../../../theme/colors';

export const StyledContainer = styled(Box)`
  width: 100%;
  min-height: 100vh;
  background-image: url(${BackgroundImage});
  background-size: cover;
`;

export const StyledInnerContainer = styled(Box)`
  grid-template-columns: minmax(730px, 1fr) minmax(710px, 1fr);

  min-height: calc(100vh - 58.4px);
`;

export const StyledHeader = styled(Typography)`
  font-size: 90px;
  line-height: 99px;
  font-weight: 700;
  color: ${COLORS.background.secondary};
`;

export const StyledSubHeader = styled(Typography)`
  font-size: 24px;
  line-height: 35px;
  color: ${COLORS.background.secondary};

  margin-top: 24px;
  width: 80%;
`;

export const StyledAboutUsContainer = styled(Box)`
  background-color: ${COLORS.background.secondary};
  height: fit-content;

  & p {
    line-height: 25px;
  }
`;
