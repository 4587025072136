export const navigationItems = {
  services: {
    id: 'services',
  },
  team: {
    id: 'team',
  },
  fcic: {
    id: 'fcic',
  },
};
