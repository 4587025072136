import { forwardRef } from 'react';
import { TextareaProps } from './Textarea.d';
import { StyledTextarea, StyledErrorText } from './Textarea.styled';
import { Box } from '../Box';

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ error, ...props }, ref) => {
    return (
      <Box display="flex" flexDirection="column" gap={0.5}>
        <StyledTextarea ref={ref} error={!!error} {...props} />
        {error && <StyledErrorText>{error}</StyledErrorText>}
      </Box>
    );
  },
);

Textarea.displayName = 'Textarea';
