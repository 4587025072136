import styled, { css } from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { ButtonProps } from './Button.d';
import { ButtonVariant } from './Button.enums';

const getButtonColor = (val: ButtonVariant) => {
  switch (val) {
    case ButtonVariant.OUTLINED:
    case ButtonVariant.PRIMARY:
      return COLORS.accent.white;
    case ButtonVariant.CONTAINED:
    case ButtonVariant.SECONDARY:
      return COLORS.info.selectedBorder;
    default:
      return COLORS.accent.white;
  }
};

const getButtonBackgroundColor = (val: ButtonVariant) => {
  switch (val) {
    case ButtonVariant.PRIMARY:
      return COLORS.info.selectedBorder;
    case ButtonVariant.CONTAINED:
      return COLORS.accent.white;
    default:
      return 'transparent';
  }
};

const getButtonBorderColor = (val: ButtonVariant) => {
  switch (val) {
    case ButtonVariant.PRIMARY:
    case ButtonVariant.SECONDARY:
      return COLORS.info.selectedBorder;
    case ButtonVariant.CONTAINED:
    case ButtonVariant.OUTLINED:
      return COLORS.accent.white;
    default:
      return COLORS.accent.primary;
  }
};

export const StyledButton = styled.button<ButtonProps>(
  ({ variant = ButtonVariant.PRIMARY, disabled = false, fullWidth }) => css`
    box-sizing: border-box;
    color: ${getButtonColor(variant)};
    font-size: ${SIZES.typography.button};
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    outline: none;
    padding: 15px 23px;
    border: ${`1px solid ${getButtonBorderColor(variant)}`};
    border-radius: 100px;
    width: ${fullWidth ? '100%' : 'fit-content'};
    display: flex;
    justify-content: center;
    transition-duration: 0.2s;

    svg {
      stroke: ${getButtonColor(variant)};
    }

    &:disabled {
      pointer-events: none;
      color: ${COLORS.typography.disabled};
      background-color: ${variant === ButtonVariant.PRIMARY
        ? COLORS.accent.disabled
        : 'transparent'};
      border: ${variant === ButtonVariant.OUTLINED
        ? `0.5px solid ${COLORS.stroke.main}`
        : 'none'};
      text-decoration: none;
      svg {
        stroke: ${COLORS.typography.disabled};
      }
    }

    &:enabled {
      background-color: ${getButtonBackgroundColor(variant)};
      cursor: pointer;

      &:hover {
        color: ${COLORS.accent.white};
        background-color: ${COLORS.accent.hover};
        border: ${`1px solid ${COLORS.accent.hover}`};

        & svg {
          stroke: ${variant === ButtonVariant.OUTLINED
            ? COLORS.accent.white
            : COLORS.stroke.main};
        }
      }

      &:active {
        color: ${getButtonColor(variant)};
        outline: ${`4px solid ${COLORS.stroke.border}`};

        svg {
          stroke: ${getButtonColor(variant)};
        }
      }
    }
  `,
);
