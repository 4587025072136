import { pxToRem } from '../utils/common';

export const SIZES = {
  fontWeight: {
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
  },
  typography: {
    h1: pxToRem(27),
    h2: pxToRem(23),
    h3: pxToRem(17),
    subtitle: pxToRem(17),
    sectionHeader: pxToRem(19),
    body1: pxToRem(15),
    body2: pxToRem(13),
    button: pxToRem(14),
    smallButton: pxToRem(12),
    caption: pxToRem(11),
    labelMedium: pxToRem(15),
    labelSmall: pxToRem(13),
    score: pxToRem(60),
    badge: pxToRem(11),
    input: pxToRem(14),
  },
  deviceSize: {
    desktop: 1440,
    tablets: 768,
    phone: 540,
    smallPhone: 450,
  },
  deviceHeight: {
    small: 770,
    medium: 930,
  },
  media: {
    desktop: '@media screen and (min-width: 1440px)',
    tablet: '@media screen and (min-width: 768px)',
    phone: '@media screen and (min-width: 450px)',
  },
  spacing: (count: number) => `${count * 0.5}rem`,
  modal: {
    fundDetails: pxToRem(400),
    small: pxToRem(412),
    medium: pxToRem(476),
    large: pxToRem(1200),
  },
};
