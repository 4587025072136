import { FC } from 'react';
import { ExpandedNavMenuProps } from './ExpandedNavMenu.d';
import {
  StyledCategory,
  StyledLink,
  StyledMenu,
  StyledMenuItem,
} from './ExpandedNavMenu.styled';

export const ExpandedNavMenu: FC<ExpandedNavMenuProps> = ({
  variant,
  anchorEl,
  categoryName,
  menuItems,
  open,
  setAnchorEl,
}) => {
  const isPrimaryVariant = variant === 'primary';

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledMenu
      id={`${categoryName}-menu`}
      isLight={isPrimaryVariant}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <StyledCategory isLight={isPrimaryVariant}>{categoryName}</StyledCategory>
      {menuItems.map((item) => (
        <StyledMenuItem
          onClick={handleClose}
          isLight={isPrimaryVariant}
          disableRipple
        >
          <StyledLink smooth to={item.link} isLight={isPrimaryVariant}>
            {item.label}
          </StyledLink>
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );
};
