import styled, { css } from 'styled-components';
import { Box } from '../Box';
import { COLORS } from '../../../theme/colors';
import { Typography } from '../Typography';
import { HashLink as Link } from 'react-router-hash-link';

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.typography.primary};
`;

export const StyledTopContainer = styled(Box)`
  grid-template-columns: 300px 415px auto;
`;

export const StyledNavLinksContainer = styled(Box)`
  grid-template-columns: 1fr 1fr;
`;

const typographyStyles = css`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: ${COLORS.typography.neutral};
`;

export const StyledTypography = styled(Typography)`
  ${typographyStyles}
`;

export const StyledLegalInfoLink = styled(Link)`
  ${typographyStyles}
  text-decoration: none;
  text-underline-offset: 3px;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledLink = styled.a`
  ${typographyStyles}
  font-size: 14px;
  text-decoration: none;
  text-underline-offset: 3px;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledNavLink = styled(Link)`
  ${typographyStyles}
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  text-underline-offset: 3px;

  &:hover {
    text-decoration: underline;
  }
`;
