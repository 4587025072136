import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

type StyledItemContainerProps = {
  isLight: boolean;
};

export const StyledHeader = styled(Typography)`
  font-size: 40px;
  line-height: 48px;
  flex: 0 1 70%;
`;

export const StyledInnerContainer = styled(Box)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 19px;
`;

export const StyledItemContainer = styled(Box)<StyledItemContainerProps>`
  background-color: ${({ isLight }) =>
    isLight ? COLORS.background.module : COLORS.info.selectedBorder};
  border-radius: 16px;
`;

export const StyledLabel = styled(Typography)`
  font-size: 24px;
  line-height: 28px;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const StyledDescription = styled(Typography)`
  line-height: 25px;
`;
