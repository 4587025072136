import { Toast } from 'components/common/Toast';
import { raiseToast } from 'components/common/Toast/raiseToast';

type Error = {
  [value: string]: string;
  message: string;
};

export const handleResponseErrors = (errors: Error | Error[]) => {
  if (Array.isArray(errors) && errors.length > 0) {
    errors.forEach((error: Error) => {
      raiseToast.error(<Toast title={error.message} content={error.desc} />);
    });
  } else {
    const error = errors as Error;
    const { message, ...restErrors } = error;
    Object.values(restErrors).forEach((errorItem: string) => {
      raiseToast.error(<Toast title={message} content={errorItem} />);
    });
  }
};
