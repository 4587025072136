import { FC } from 'react';
import { StyledContainer, StyledDescription } from './Recommendations.styled';
import { Typography } from '../../common/Typography';
import { COLORS } from 'theme/colors';
import { Box } from '@mui/material';
import { Button } from '../../common/Button';
import { ButtonVariant } from '../../common/Button/Button.enums';
import { useNavigate } from 'react-router';
import { AppPaths } from 'urls/frontend';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ContactCategory,
  ContactRecommendationsAction,
} from 'utils/google-analytics/events/contacts';

export const Recommendations: FC = () => {
  const navigate = useNavigate();

  const onContactSalesClick = () => {
    TrackGoogleAnalyticsEvent(
      ContactCategory,
      ContactRecommendationsAction,
      window.location.pathname,
    );
    navigate(AppPaths.contacts);
  };

  return (
    <StyledContainer
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={3}
      py={12.5}
    >
      <Typography variant="h2" color={COLORS.background.secondary}>
        Handlungsempfehlungen für Investmentfond
      </Typography>
      <StyledDescription
        variant="body"
        color={COLORS.background.secondary}
        textAlign="center"
      >
        Für unsere Kunden entwickeln wir fundierte Handlungsempfehlungen
        (Kaufen/Halten/Verkaufen) zu einer breiten Palette von Investmentfonds.
        Diese Empfehlungen basieren auf gründlichen Analysen und bieten
        wertvolle Orientierung für Anlageentscheidungen. Möchten Sie Zugang zu
        diesen Einschätzungen erhalten? Registrieren Sie sich für die
        FondsConsult Datenbank und profitieren Sie von unserer Expertise.
      </StyledDescription>
      <Box display="flex" gap={2} pt={2}>
        <Button variant={ButtonVariant.OUTLINED} onClick={onContactSalesClick}>
          Contact sales
        </Button>
        {/* <Button variant={ButtonVariant.CONTAINED}>Get started for free</Button> */}
      </Box>
    </StyledContainer>
  );
};
