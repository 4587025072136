import styled, { css } from 'styled-components';
import { COLORS } from 'theme/colors';
import { pxToRem } from 'utils/common';
import { SIZES } from 'theme/sizes';

export type FieldBasic = {
  error?: boolean;
};

export const StyledTextarea = styled.textarea<FieldBasic>`
  ${({ error }) => css`
    box-sizing: border-box;
    border: ${error
      ? `1px solid ${COLORS.error.main}`
      : `1px solid ${COLORS.stroke.primary}`};
    border-radius: 4px;
    outline: none;
    padding: ${pxToRem(16)} ${pxToRem(16)};
    width: 100%;
    font-size: ${SIZES.typography.input};
    line-height: 20px;
    color: ${COLORS.typography.main};
    font-family: 'Inter', sans-serif;
    height: 49px;
    transition-duration: 0.2s;

    &::placeholder {
      color: ${COLORS.typography.placeholder};
    }
    &:enabled {
      &:hover {
        border: 1px solid ${COLORS.typography.caption};
      }

      &:focus {
        border: 4px solid ${COLORS.stroke.border};
      }
    }

    &:disabled {
      background-color: ${COLORS.accent.disabled};
      color: ${COLORS.stroke.main};
    }
  `}
  min-height: 166px;
  resize: vertical;
`;

export const StyledErrorText = styled.p`
  color: ${COLORS.error.main};
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
`;
