import { CONTACT_US_URL } from 'urls/api';
import fetchApi from './fetchApi';
import { ContactUsForm } from 'types/Contact';

export const contactUs = (data: ContactUsForm) => {
  return fetchApi(CONTACT_US_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
};
