export const COLORS = {
  accent: {
    primary: '#0066FF',
    white: '#FFFFFF',
    secondary: '#2F2E35',
    hover: '#4180FE',
    disabled: '#F2F2F2',
    border: '#DFE1E6',
    primaryHover: '#0F1B5D',
  },
  typography: {
    main: '#000000',
    primary: '#242E49',
    secondary: '#4A5568',
    body: '#2D3748',
    disabled: '#939299',
    caption: '#939299',
    placeholder: '#828897',
    description: '#4C556B',
    info: '#CAE0FF',
    neutral: '#E7F1FF',
  },
  stroke: {
    main: '#E6E6E6',
    primary: '#E4E6EB',
    secondary: '#00214D',
    border: '#B3CCFF',
  },
  info: {
    main: '#EDF2FF',
    secondary: '#F7F8FD',
    border: '#07004D',
    selectedBorder: '#1167FE',
  },
  error: {
    main: '#FE0100',
    secondary: '#FB3640',
    background: '#FA4E5E',
    hover: '#FCEBE9',
    text: '#FFFFFF',
  },
  success: {
    main: '#217042',
    background: '#EAF6E8',
    typography: '#055908',
    secondary: '#10B981',
    primary: '#1FB141',
    hover: '#6FCF97B2',
    progress: '#07BE11',
    light: '#EDFAED',
    bulletPoint: '#0BBE12',
    border: '#D6EAD6',
  },
  warning: {
    main: '#FFC600',
    background: '#FEF8E6',
    border: '#FFE7A0',
    secondary: '#FBBD05',
    hover: '#F2994A4D',
  },
  background: {
    primary: '#EDF4FF',
    secondary: '#FFFFFF',
    neutral: '#E5EFFE',
    header: '#EBF5FF',
    grey: '#F7F7F7',
    module: '#F3F5F9',
    tableTab: '#F9FBFE',
    tableHead: '#F9FAFC',
  },
};
