import styled, { css } from "styled-components";
import { SIZES } from "theme/sizes";
import { StyledBoxProps } from "./Box.d";

const boxStyles = css<StyledBoxProps>`
  ${({ color }) => color && `color: ${color};`}
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  ${({ $display }) => $display && `display: ${$display};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}
  ${({ justifyItems }) => justifyItems && `justify-items: ${justifyItems};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}
  ${({ gridAutoFlow }) => gridAutoFlow && `grid-auto-flow: ${gridAutoFlow};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ grid }) => grid && `grid: ${grid};`}
  ${({ order }) => order != null && `order: ${order};`}
  ${({ gridGap }) => gridGap && `grid-gap: ${SIZES.spacing(gridGap)};`}
  ${({ gap }) => gap && `gap: ${SIZES.spacing(gap)};`}
  ${({ p }) => (p || p === 0) && `padding: ${SIZES.spacing(p)};`}
  ${({ px }) =>
    (px || px === 0) &&
    `padding-left: ${SIZES.spacing(px)}; padding-right: ${SIZES.spacing(px)};`}
  ${({ py }) =>
    (py || py === 0) &&
    `padding-top: ${SIZES.spacing(py)}; padding-bottom: ${SIZES.spacing(py)};`}
  ${({ pt }) => (pt || pt === 0) && `padding-top: ${SIZES.spacing(pt)};`}
  ${({ pb }) => (pb || pb === 0) && `padding-bottom: ${SIZES.spacing(pb)};`}
  ${({ pl }) => (pl || pl === 0) && `padding-left: ${SIZES.spacing(pl)};`}
  ${({ pr }) => (pr || pr === 0) && `padding-right: ${SIZES.spacing(pr)};`}
  ${({ m }) => (m || m === 0) && `margin: ${SIZES.spacing(m)};`}
  ${({ mx }) =>
    (mx || mx === 0) &&
    `margin-left: ${SIZES.spacing(mx)}; margin-right: ${SIZES.spacing(mx)};`}
  ${({ my }) =>
    (my || my === 0) &&
    `margin-top: ${SIZES.spacing(my)}; margin-bottom: ${SIZES.spacing(my)};`}
  ${({ mt }) => (mt || mt === 0) && `margin-top: ${SIZES.spacing(mt)};`}
  ${({ mb }) => (mb || mb === 0) && `margin-bottom: ${SIZES.spacing(mb)};`}
  ${({ ml }) => (ml || ml === 0) && `margin-left: ${SIZES.spacing(ml)};`}
  ${({ mr }) => (mr || mr === 0) && `margin-right: ${SIZES.spacing(mr)};`}
`;

export const StyledDiv = styled.div<StyledBoxProps>`
  ${boxStyles}
`;

export const StyledSpan = styled.span<StyledBoxProps>`
  ${boxStyles}
`;
