import React, { FC, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { routes } from './config/routes';
import { StyledToastContainer } from 'components/common/Toast/Toast.styled';
import InitializeGoogleAnalytics from 'utils/google-analytics';

function App() {
  const getRoute = (path: string, Component: FC) => {
    return <Route key={path} path={path} element={<Component />} />;
  };

  useEffect(() => {
    InitializeGoogleAnalytics();
  }, []);

  return (
    <>
      <StyledToastContainer />
      <Router>
        <Routes>
          {routes.map(({ path, component }) => getRoute(path, component))}
        </Routes>
      </Router>
    </>
  );
}

export default App;
