import { HeaderSection } from 'components/common/HeaderSection';
import { FC, useEffect, useState } from 'react';
import HeaderBackground from 'assets/backgrounds/about-us.jpg';
import { StyledFixedHeaderContainer } from './AboutUs.styled';
import { Header } from 'components/common/Header';
import { ContactSection } from 'components/common/ContactSection';
import { Footer } from 'components/common/Footer';
import { FCICSection } from 'components/AboutUs/FCICSection';
import { Services } from 'components/AboutUs/Services/Services';
import { Team } from 'components/AboutUs/Team';

export const AboutUs: FC = () => {
  const [showFixedHeader, setShowFixedHeader] = useState(false);

  const handleScroll = () => {
    const section = document.querySelector('.services-section');
    if (section) {
      const sectionTop = section.getBoundingClientRect().top + window.scrollY;
      const offset = window.scrollY;

      if (offset >= sectionTop) {
        setShowFixedHeader(true);
      } else {
        setShowFixedHeader(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeaderSection
        label="Über uns"
        description="Mehrwert durch unabhängige Fondsanalyse Innovativ & Bewährt"
        backgroundUrl={HeaderBackground}
      />
      {showFixedHeader && (
        <StyledFixedHeaderContainer showFixedHeader={showFixedHeader}>
          <Header expandableMenuVariant="secondary" />
        </StyledFixedHeaderContainer>
      )}
      <Services />
      <Team />
      <FCICSection />
      <ContactSection />
      <Footer />
    </>
  );
};
