import { FC, useState } from 'react';
import { NavigationMenuProps } from './NavigationMenu.d';
import { Box } from '../Box';
import { StyledButton, StyledLink } from './NavigationMenu.styled';
import { ExpandedNavMenu } from './ExpandedNavMenu';
import { navigationItems } from './config';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down.svg';
import { COLORS } from '../../../theme/colors';
import { AppPaths } from 'urls/frontend';

export const NavigationMenu: FC<NavigationMenuProps> = ({
  variant,
  expandableMenuVariant,
}) => {
  const isPrimaryVariant = variant === 'primary';
  const [aboutUsAnchorEl, setAboutUsAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [methodologyAnchorEl, setMethodologyAnchorEl] =
    useState<null | HTMLElement>(null);
  const openAboutUs = Boolean(aboutUsAnchorEl);
  const openMethodology = Boolean(methodologyAnchorEl);

  const handleClickAboutUs = (event: React.MouseEvent<HTMLElement>) => {
    setAboutUsAnchorEl(event.currentTarget);
  };

  const handleClickMethodology = (event: React.MouseEvent<HTMLElement>) => {
    setMethodologyAnchorEl(event.currentTarget);
  };

  return (
    <Box display="flex" alignItems="center" gap={4}>
      <StyledButton
        open={openAboutUs}
        variant={variant}
        onClick={handleClickAboutUs}
      >
        <>Über uns</>
        <ArrowIcon
          stroke={
            isPrimaryVariant
              ? COLORS.typography.main
              : COLORS.background.secondary
          }
        />
      </StyledButton>
      <ExpandedNavMenu
        variant={expandableMenuVariant}
        categoryName={navigationItems.aboutUs.categoryName}
        menuItems={navigationItems.aboutUs.items}
        anchorEl={aboutUsAnchorEl}
        open={openAboutUs}
        setAnchorEl={setAboutUsAnchorEl}
      />
      <StyledButton
        open={openMethodology}
        variant={variant}
        onClick={handleClickMethodology}
      >
        <>Methodik</>
        <ArrowIcon
          stroke={
            isPrimaryVariant
              ? COLORS.typography.main
              : COLORS.background.secondary
          }
        />
      </StyledButton>
      <ExpandedNavMenu
        variant={expandableMenuVariant}
        categoryName={navigationItems.methodology.categoryName}
        menuItems={navigationItems.methodology.items}
        anchorEl={methodologyAnchorEl}
        open={openMethodology}
        setAnchorEl={setMethodologyAnchorEl}
      />
      <StyledLink to={AppPaths.contacts} variant={variant}>
        Kontakt
      </StyledLink>
    </Box>
  );
};
