import { forwardRef } from 'react';
import {
  StyledContainer,
  StyledInput,
  InputIcon,
  StyledErrorText,
} from './Input.styled';
import { InputProps } from './Input.d';
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { label, error, disabled, id, name = id, required, type, ...props },
    ref,
  ) => {
    return (
      <StyledContainer display="flex" flexDirection="column" gap={0.5}>
        <StyledInput
          id={id}
          name={name}
          disabled={disabled}
          error={!!error}
          ref={ref}
          {...props}
        />
        {error && <StyledErrorText>{error}</StyledErrorText>}
        {error && (
          <InputIcon>
            <DangerIcon />
          </InputIcon>
        )}
      </StyledContainer>
    );
  },
);

Input.displayName = 'Input';
