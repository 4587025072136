import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { COLORS } from 'theme/colors';

export const StyledToastContainer = styled(ToastContainer)`
  --toastify-toast-width: 360px;

  --toastify-toast-bd-radius: 4px;

  --toastify-color-light: #fff;
  --toastify-color-dark: ${COLORS.accent.secondary};
  --toastify-color-info: ${COLORS.accent.primary};
  --toastify-color-success: ${COLORS.success.secondary};
  --toastify-color-warning: ${COLORS.warning.main};
  --toastify-color-error: ${COLORS.error.secondary};

  --toastify-color-progress-info: ${COLORS.accent.primary};
  --toastify-color-progress-success: ${COLORS.success.secondary};
  --toastify-color-progress-warning: ${COLORS.warning.main};
  --toastify-color-progress-error: ${COLORS.error.secondary};

  .Toastify__toast-body {
    align-items: flex-start;
  }

  .Toastify__toast-icon {
    margin-top: 2px;
  }
`;
