import { Box } from 'components/common/Box';
import { FC } from 'react';
import {
  StyledHeader,
  StyledTeamMembersContainer,
  StyledTextContainer,
} from './Team.styled';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { teamMembers } from './config';
import { TeamMember } from './TeamMember';
import { navigationItems } from 'views/AboutUs/config';

export const Team: FC = () => {
  const { team } = navigationItems;

  return (
    <Box id={team.id} pt={12.5} px={8} pb={12.5}>
      <StyledTextContainer pb={8.75}>
        <StyledHeader variant="h2" textAlign="center">
          Das Team
        </StyledHeader>
        <Typography
          variant="subtitle"
          color={COLORS.typography.description}
          textAlign="center"
        >
          Unser erfahrenes Team, bestehend aus einer Führungsmannschaft mit
          durchschnittlich über 20 Jahren Branchenerfahrung und sechs
          hochqualifizierten Analysten, vereint umfassende Expertise in
          Investment-Analyse und Beratung. Die Stabilität unseres Teams
          gewährleistet kontinuierliche Qualität und Zuverlässigkeit in allen
          Aspekten unserer Dienstleistungen, stets ausgerichtet auf die
          spezifischen Anforderungen unserer Investoren.
        </Typography>
      </StyledTextContainer>
      <StyledTeamMembersContainer display="grid">
        {teamMembers.map((item) => (
          <TeamMember
            key={item.id}
            name={item.name}
            position={item.position}
            photo={item.photo}
            id={item.id}
            linkedingLink={item.linkedingLink}
          />
        ))}
      </StyledTeamMembersContainer>
    </Box>
  );
};
