import { FC } from 'react';
import { Box } from '../../common/Box';
import { StyledContainer, StyledRightContainer } from './FCICSection.styled';
import { Typography } from '../../common/Typography';
import { navigationItems } from 'views/AboutUs/config';

export const FCICSection: FC = () => {
  const { fcic } = navigationItems;

  return (
    <StyledContainer
      id={fcic.id}
      display="grid"
      alignItems="center"
      className="about-us"
    >
      <Box pl={8} pr={14.5} display="flex" flexDirection="column" gap={3}>
        <Typography variant="h2">
          FondsConsult Investment Committee (FCIC){' '}
        </Typography>
        <Typography variant="body">
          Unser sechsköpfiges FCIC, bestehend aus fünf erfahrenen Analysten und
          einem Vorsitzenden, erarbeitet fundierte Handlungsempfehlungen für ein
          breites Spektrum von Investmentfonds. In regelmäßigen Sitzungen werden
          Empfehlungen für Publikumsfonds, ETFs und illiquide Fonds wie ELTIFs
          intensiv diskutiert und per Abstimmung festgelegt. Dabei kombinieren
          wir die präzise FCR-Quant-Methodik mit qualitativen
          Experteneinschätzungen. Unser Ziel: unabhängige, sorgfältig geprüfte
          Empfehlungen, die unseren Kunden eine solide Grundlage für ihre
          Anlageentscheidungen in der dynamischen Finanzwelt bieten.
        </Typography>
      </Box>
      <StyledRightContainer />
    </StyledContainer>
  );
};
