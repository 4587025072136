import { FC } from 'react';
import { ChipProps } from './Chip.d';
import { StyledChip } from './Chip.styled';

export const Chip: FC<ChipProps> = ({
  label,
  customSize = 'small',
  ...props
}) => {
  return <StyledChip customSize={customSize} label={label} {...props} />;
};
