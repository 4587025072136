export const ContactCategory: string = 'Contact';
export const ContactMainSectionAction: string =
  'Main section, Contact button: clicked';
export const ContactOurApproachAction: string =
  'Our approach, Contact button: clicked';
export const ContactRecommendationsAction: string =
  'Recommendations, Contact button: clicked';
export const ContactAboutUsAction: string = 'AboutUs, Contact button: clicked';
export const ContactSectionAction: string =
  'Contact section, Contact button: clicked';
export const ContactMethodologyAction: string =
  'Methodology, Contact button: clicked';
