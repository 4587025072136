export const navigationItems = [
  {
    label: 'Unser Fondselektion',
    id: 'fund-selection-approach',
  },
  {
    label: 'Smart Fund Benchmarking',
    id: 'smart-fund-benchmarking',
  },
  {
    label: 'ESG Credibility Score',
    id: 'esg-credibility-score',
  },
  {
    label: 'Private Markets & Real Estate Fonds',
    id: 'private-markets-real-estate-fonds',
  },
];
